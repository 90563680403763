
import layout from '@/views/FrameBase/Main.vue'
import { Component, Vue } from 'vue-property-decorator'
import { errorMessage } from '@/utils/message'
import { wallet } from '@/utils/wallet'
import { checkDomain, getOwner } from '@/sdk/domain'
import { lang } from '@/lang'
import { verifyTwitter } from '@/utils/useVerify'

@Component({
  components: { layout }
})
export default class TwitterValid extends Vue {
  private get text () {
    return {
      title: lang('TWITTER_VALID_PAGE_TITLE'),
      step1: lang('TWITTER_VALID_PAGE_STEP_1'),
      step2: lang('TWITTER_VALID_PAGE_STEP_2'),
      title1: lang('TWITTER_VALID_PAGE_STEP_TITLE_1'),
      des1: lang('TWITTER_VALID_PAGE_STEP_1_DES_1'),
      des2: lang('TWITTER_VALID_PAGE_STEP_1_DES_2'),
      btn1: lang('TWITTER_VALID_PAGE_POST'),
      btn2: lang('TWITTER_VALID_PAGE_CONFIRM'),
      title3: lang('TWITTER_VALID_PAGE_STEP_TITLE_2'),
      des3: lang('TWITTER_VALID_PAGE_STEP_URL')
    }
  }

  private status = { verifySuccess: false }

  private infoRaw = {
    twitterId: '',
    twitterAddress: '',
    domain: '',
    wallet: '',
    signature: ''
  }

  private get info () {
    return {
      twitterId: this.infoRaw.twitterId.replace('@', '').trim(),
      twitterAddress: this.infoRaw.twitterAddress.trim(),
      domain: this.infoRaw.domain,
      wallet: this.infoRaw.wallet,
      signature: this.infoRaw.signature
    }
  }

  private loading = {
    signing: false,
    verifying: false
  }

  private get walletAddress () {
    return wallet.accountId
  }

  private goManager () {
    this.$router.push(`/details/${this.info.domain}?tab=EDIT_RECORD&twitter=https://twitter.com/${this.info.twitterId}`).catch(e => { console.log(e.message) })
  }

  private postTwitter = async () => {
    this.loading.signing = true
    try {
      if (!this.info.twitterId) {
        errorMessage('Twitter ID cannot be empty')
        return
      }

      const domain = ((this.$route.query.domain || '') as string).toLowerCase()

      // 判断有没有传递 domain 字符串
      if (!domain) {
        errorMessage('Invalid domain')
        return
      }

      // 判断 domain 是否合法
      if (!checkDomain(domain, {})) {
        errorMessage('Invalid domain')
        return
      }

      // 判断是否链接钱包，没登陆填出链接钱包提示框
      if (!this.walletAddress || this.walletAddress === '') {
        await wallet.connect()
      }

      // 判断 domain 是否属于当前钱包
      const domainOwner = await getOwner(domain) || ''
      if (domainOwner.toLowerCase() !== wallet.accountId) {
        errorMessage('Permission deny')
        return
      }

      const msgParams = {
        types: {
          EIP712Domain: [],
          Data: [
            { name: 'Message', type: 'string' }
          ]
        },
        domain: {},
        primaryType: 'Data',
        message: {
          Message: `I'm verifying my Twitter account with handle ${this.info.twitterId}`
        }
      }

      const sign = await (window as any).ethereum.request({
        method: 'eth_signTypedData_v3',
        params: [this.walletAddress, JSON.stringify(msgParams)]
      })

      const msg = `I'm verifying my Twitter account with handle ${this.info.twitterId} \n\n https://www.pns.link/details/${domain} \n\nsig: ${sign}`
      this.infoRaw.domain = domain
      this.infoRaw.wallet = this.walletAddress
      this.infoRaw.signature = sign
      this.sendTwitter(msg)
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.signing = false
    }
  }

  private valid = async () => {
    const { domain, wallet, twitterId, signature, twitterAddress } = this.info
    try {
      const res = await verifyTwitter(domain, wallet, twitterId, signature, twitterAddress)
      if (res.result === 'ok') {
        this.status.verifySuccess = true
      } else if (res.result === 'fail') {
        errorMessage('Verify fail, please check the information and try again.')
      } else {
        if (res.message) errorMessage(res.message)
      }
    } catch (e) {
      errorMessage(e.message)
      console.error('[app-verifyTwitter]:' + e.message)
    }
  }

  private sendTwitter (msg: string) {
    const messageText = encodeURIComponent(msg)
    window.open(`https://twitter.com/intent/tweet?text=${messageText}`)
  }
}
